import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="App-image">
        <img src={require("./tomme.jpg").default} alt="A tomme" />
      </div>
      <div className="App-title">
        <h1>
          Mange de la tomme<br></br>
          sur <a href="https://www.cheeta-holds.com">shape-rider.com</a>
        </h1>
        <h3 className="App-quote">- Frantz</h3>
      </div>
    </div>
  );
}

export default App;
